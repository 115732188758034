import ColorIdentifier from '@components/ui/ColorIdentifier'
import config from '@config/seo_meta.json'
import cn from 'clsx'
import throttle from 'lodash.throttle'
import shuffle from 'lodash/shuffle'
import Image from 'next/legacy/image'
import Link from 'next/link'
import { useEffect, useState } from 'react'
import Slider from 'react-slick'

interface Props {
  slice: any
}
const HomePageSliderBanner = ({ slice }: Props) => {
  const [shuffledItems, setShuffledItems] = useState(slice?.items || [])
  const [hasScrolled, setHasScrolled] = useState(false)
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: true,
  }

  useEffect(() => {
    const handleScroll = throttle(() => {
      const offset = 0
      const { scrollTop } = document.documentElement
      const scrolled = scrollTop > offset

      if (hasScrolled !== scrolled) {
        setHasScrolled(scrolled)
      }
    }, 200)

    document.addEventListener('scroll', handleScroll)
    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [hasScrolled])

  useEffect(() => {
    setShuffledItems(shuffle(slice?.items || []))
  }, [slice?.items])

  return (
    <section
      className={cn('-mt-14.2 w-full transition-all duration-500', {
        'lg:-mt-16': hasScrolled,
        'sm:-mt-24 lg:-mt-25 xl:-mt-27': !hasScrolled,
      })}
    >
      <Slider {...settings}>
        {shuffledItems?.map((item: any, index: number) => {
          const colorName = item?.color
          const colorHexCode = item?.colorHexCode
          const color = { colorName, colorHexCode }
          const leftImage = item?.leftImage
          const rightImage = item?.rightImage
          const bannerText = item?.bannerText
          const linkTitle = item?.linkTitle
          const linkUrl = item?.linkUrl
          const colorUrl = item?.colorUrl

          return (
            <div key={colorHexCode} className="relative mx-auto w-full">
              <div className="flex flex-col items-center justify-center lg:flex-row ">
                <div className="relative w-full text-img lg:w-1/2">
                  <Link href={!colorUrl ? '/' : colorUrl} legacyBehavior>
                    <a>
                      {leftImage?.url && (
                        <Image
                          layout="responsive"
                          height={810}
                          width={720}
                          src={leftImage.url}
                          alt={leftImage?.alt || config.openGraph.images[0].alt}
                          priority
                        />
                      )}

                      <ColorIdentifier
                        className="absolute bottom-4 left-4 block lg:hidden"
                        color={color}
                      />
                    </a>
                  </Link>
                </div>
                <div className="hidden w-1/2 text-img lg:block">
                  {rightImage?.url && (
                    <Image
                      layout="responsive"
                      height={810}
                      width={720}
                      src={rightImage.url}
                      alt={rightImage?.alt || config.openGraph.images[0].alt}
                      priority
                    />
                  )}
                </div>
                <div className="relative block h-64.5 w-full text-img lg:hidden">
                  {rightImage?.url && (
                    <Image
                      objectFit="cover"
                      layout="fill"
                      src={rightImage.url}
                      alt={rightImage?.alt || config.openGraph.images[0].alt}
                    />
                  )}
                  {bannerText && (
                    <div className="heading-mobile-4 absolute left-4 bottom-17 right-4 max-w-168 text-primary">
                      {bannerText}
                    </div>
                  )}
                  {linkUrl && (
                    <Link href={linkUrl || '/'} legacyBehavior>
                      <a className="body-1 text-decoration-line: absolute bottom-7 left-4 text-primary underline lg:bottom-16 lg:left-16">
                        {linkTitle}
                      </a>
                    </Link>
                  )}
                </div>
                <div className="absolute bottom-0 hidden w-full pb-14 lg:block">
                  <div className="wrapper flex items-end">
                    <div className="w-1/2">
                      <Link href={!colorUrl ? '/' : colorUrl} legacyBehavior>
                        <a>
                          <ColorIdentifier color={color} />
                        </a>
                      </Link>
                    </div>
                    <div className="w-1/2 pl-14">
                      <h1 className="heading-4 mb-6 text-primary">
                        {bannerText}
                      </h1>
                      <Link href={linkUrl || '/'} legacyBehavior>
                        <a className="body-1 text-decoration-line: text-primary underline">
                          {linkTitle}
                        </a>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </Slider>
      <style jsx>
        {`
          .circle:hover {
            position: absolute;
            bottom: 4rem;
            left: 4rem;
          }
        `}
      </style>
    </section>
  )
}

export default HomePageSliderBanner
