import cn from 'clsx'
interface Props {
  color?: {
    colorName: string
    colorHexCode: string
  }
  className?: string
  vertialText?: boolean
  prefilled?: boolean
}
const ColorIdentifier = ({
  color,
  className,
  vertialText,
  prefilled = false,
}: Props) => {
  const rootClassName = cn('flex items-center gap-3', className)
  return (
    <>
      <div className={rootClassName}>
        <div
          className={cn('circle h-5 w-5 rounded-full ', {
            'border border-white': !prefilled,
          })}
        />
        <div className="lg:label-large label colourLabel text-primary">
          {color?.colorName}
        </div>
      </div>
      <style jsx>
        {`
          .circle {
            background-color: ${prefilled && color?.colorHexCode
              ? color.colorHexCode
              : 'transparent'};
          }
          .circle:hover {
            background-color: ${color?.colorHexCode};
          }
          .colourLabel {
            writing-mode: ${vertialText ? 'vertical-rl' : 'horizontal-tb'};
          }
        `}
      </style>
    </>
  )
}
export default ColorIdentifier
